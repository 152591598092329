import "./App.css";
import { Routes, Route } from "react-router-dom";
import { routes } from "./routes";

import { Lang } from "./Lang";
import "./fonts/stylesheet.css";
import { useState } from "react";
import Posad from "./Pages/Posad";
import Thanks from "./Pages/Thanks/indes";
import Landing from "./Pages/Landing";

function App() {
  const [lang, setLang] = useState("ru");
  return (
    <div className="App">
      <Lang.Provider value={[lang, setLang]}>
        <Routes>
          <Route path={routes.posad} element={<Posad></Posad>}></Route>
          <Route path={routes.thanks} element={<Thanks></Thanks>}></Route>
          <Route path={routes.main} element={<Landing></Landing>}></Route>
        </Routes>
      </Lang.Provider>
    </div>
  );
}

export default App;
