import "./style.css";
import logo from "./img/logo.svg";
import tg from "./img/tg.svg";
import wt from "./img/wt.svg";
import line from "./img/line.svg";
import Rotate from "react-reveal/Rotate";
import Zoom from "react-reveal/Zoom";
import aaa from "./img/aaa.svg";
import video from "./img/video.png";
import { useState } from "react";
import FormL from "../../Form";
export default function HeaderBl({ openForm }) {
  return (
    <div className="landheader">
      <div className="landTop">
        <div className="headLogo">
          <img src={logo} alt="" />
          <div>
            <p>Реализовано </p>
            <span>более 10 проектов</span>
          </div>
        </div>
        <div className="rightTab">
          <div
            className="button"
            onClick={() => {
              openForm(true);
            }}
          >
            Забронировать показ <div className="flare"></div>
          </div>
          <p>Задайте ваш вопрос</p>
          <a href="https://t.me/oporadigital_bot" target="_blank">
            <img src={tg} alt="" />
          </a>
          <a href="https://t.me/oporadigital_bot" target="_blank">
            <img src={wt} alt="" />
          </a>

          <a href="tel:+84 38 242 5892" className="tels">
            +84 38 242 5892
          </a>
        </div>
      </div>
      <div className="landHeadCont">
        <div
          className="vidik"
          onClick={() => {
            openForm(true);
          }}
        >
          <img className="click" src={aaa} alt="" />
          <img className="clicki" src={video} alt="" />
        </div>
        <div className="leftHeadLand">
          <Rotate top left>
            <h1>Недвижимость</h1>
          </Rotate>
          <Rotate top right>
            <h3>на Бали</h3>
          </Rotate>
          <div className="lineInv">
            <img src={line} alt="" />
            <p>
              от 70.000$<span>до Premium</span>
            </p>
          </div>
        </div>
        <div className="pluses">
          <div>
            <Zoom delay={300}>
              <p>
                <b>+40% </b>к активам
              </p>{" "}
            </Zoom>
            <span>На момент завершения строительства</span>
          </div>
          <div>
            <Zoom delay={500}>
              <p>
                <b>+22% </b> годовых
              </p>{" "}
            </Zoom>
            <span>Стабильный рост капитализации</span>
          </div>
        </div>
        <div className="mainButt">
          <div
            className="button"
            onClick={() => {
              openForm(true);
            }}
          >
            Получить предложение<div className="flare"></div>
          </div>
          <p>
            От ТОПового <br /> застройщика на Бали
          </p>
        </div>
      </div>
    </div>
  );
}
