import "./style.css";
import logo from "./img/logo.png";
import ic1 from "./img/ic1.svg";
import ic2 from "./img/ic2.svg";
import man from "./img/man.png";
import arrow from "./img/arrow.png";
import mobMan from "./img/manMob.png";
export default function Thanks() {
  return (
    <div className="thanks">
      <div className="thanksCont">
        <div className="leftThanks">
          <img src={logo} alt="" className="logo" />
          <h1>ПОЗДРАВЛЯЕМ!</h1>
          <div className="tabk">
            <div className="invest">
              <img src={ic1} alt="" />
              <p>У вас все получилось</p>
            </div>
            <div className="kes">
              <img src={ic2} alt="" />
              <p>
                <b>В самое ближайшее время с вами свяжется</b> <br />
                наш специалист и даст подробные ответы на все вопросы
              </p>
            </div>
          </div>
          <div className="loska">
            <div className="linePosad"></div>
            <div className="loskaCont">
              <b>
                <span>А пока подписывайтесь </span>
                на нашего Телеграм бота
              </b>
              <p>
                и заберите PDF “Расчет доходности от сдачи в аренду” <br />и
                БОНУС - уникальное видео о комплексе
              </p>
            </div>
          </div>
          {document.body.clientWidth > 640 && (
            <div className="flexics">
              <div>
                <a
                  href="https://t.me/rdi_estate_bot"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <div className="button">
                    ПОДПИСАТЬСЯ <span class="flare"></span>
                  </div>
                </a>

                <p>Обещаем - никакого спама!</p>
              </div>
              <img src={arrow} alt="" />
            </div>
          )}
        </div>
        <div className="rightThanks">
          <img src={document.body.clientWidth > 640 ? man : mobMan} alt="" />
        </div>
        {document.body.clientWidth < 640 && (
          <div className="flexics">
            <div>
              <a
                href="https://t.me/rdi_estate_bot"
                target="_blank"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className="button">
                  ПОДПИСАТЬСЯ <span class="flare"></span>
                </div>
              </a>

              <p>Обещаем - никакого спама!</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
