import img1 from "./img/img1.png";
import img2 from "./img/img2.png";
import icon1 from "./img/bot.svg";
import strel from "./img/strel.svg";
import palm from "./img/palm.png";
import botPic from "./img/botPic.png";
import "./style.css";
import { Zoom } from "react-reveal";
export default function Bot() {
  return (
    <div className="bot">
      <div className="botContent">
        <div className="leftBot">
          <div className="botTitle">Только в нашем</div>
          <h2>
            Telegram <img src={icon1} alt="" />
          </h2>
          <h3>
            <img src={img1} alt="" />
            боте
          </h3>
          <ul>
            <Zoom top>
              <li>
                <div>1</div>
                <p>Онлайн-экскурсии по объектам</p>
              </li>
            </Zoom>
            <Zoom top>
              <li>
                <div>2</div>
                <p>Аналитические отчёты и прогнозы рынка недвижимости</p>
              </li>
            </Zoom>
            <Zoom top>
              <li>
                <div>3</div>
                <p>Эксклюзивные предложения Бали</p>
              </li>
            </Zoom>
            <Zoom top>
              <li>
                <div>4</div>
                <p>Обзор юридических аспектов</p>
              </li>
            </Zoom>
            <Zoom top>
              <li>
                <div>5</div>
                <p>
                  Бесплатные консультации{" "}
                  {document.body.clientWidth < 640 && <br />} с экспертами
                </p>
              </li>
            </Zoom>
          </ul>
          {document.body.clientWidth > 640 && (
            <div className="subs">
              <a
                href="https://t.me/rdi_estate_bot"
                target="_blank"
                className="button"
                style={{ textDecoration: "none" }}
              >
                ПОДПИСАТЬСЯ НА БОТА
                <span class="flare"></span>
              </a>
              <div className="nos">
                <img src={strel} alt="" />
                <Zoom>
                  <p>Обещаем - никакого спама!</p>
                </Zoom>
              </div>
            </div>
          )}
        </div>
        <div className="rightBot">
          <img src={palm} className="palmBot" alt="" />
          <img src={botPic} className="botPic" alt="" />
          <img className="fonic" src={img2} alt="" />
          {document.body.clientWidth < 640 && (
            <div className="subs">
              <a
                href="https://t.me/rdi_estate_bot"
                target="_blank"
                className="button"
                style={{ textDecoration: "none" }}
              >
                ПОДПИСАТЬСЯ НА БОТА
                <span class="flare"></span>
              </a>
              <div className="nos">
                <img src={strel} alt="" />
                <p>Обещаем - никакого спама!</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
