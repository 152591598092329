import "./style.css";
import icon1 from "./img/ic1.svg";
import icon2 from "./img/ic2.svg";
import icon3 from "./img/ic3.svg";
import icon4 from "./img/ic4.svg";
import ic1 from "./img/f1.svg";
import ic2 from "./img/f2.svg";
import Bounce from "react-reveal/Bounce";
import ic3 from "./img/f3.svg";
import ic4 from "./img/f4.svg";
import i1 from "./img/1.png";
import i2 from "./img/2.png";
import i3 from "./img/3.png";
import m1 from "./img/m1.png";
import m2 from "./img/m2.png";
import m3 from "./img/m3.png";
import m4 from "./img/m4.png";
import i4 from "./img/4.png";
import type1 from "./img/type1.svg";
import type2 from "./img/type2.svg";
import type3 from "./img/type3.svg";
import fonic from "./img/fonic.png";
import nout from "./img/nout.png";
import ii from "./img/ici.svg";
import alm1 from "./img/alm1.png";
import alm2 from "./img/alm2.png";
import crug from "./img/crug.png";
import palm from "./img/palm.png";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Zoom from "react-reveal/Zoom";
import notic from "./img/noutic.png";
import mobCen from "./img/mobCen.png";
import { useState } from "react";
export default function FourthBl({ openForm }) {
  const [slider, setSlider] = useState(1);
  return (
    <div className="fourthBl">
      <div className="fourthContent">
        <div className="fourthLeft">
          <h2>Жизнь</h2>
          <h3>со знаком люкс</h3>
          <ul>
            <Fade left cascade>
              <li>
                <img src={ic1} alt="" />
                <p>Панорамные окна в пол с видом на океан</p>
              </li>
            </Fade>
            <Fade right cascade>
              <li>
                <img src={ic2} alt="" />
                <p>
                  В интерьере используются натуральные материалы, включая
                  натуральный камень, дерево, стекло и дорогую мебель.
                </p>
              </li>
            </Fade>
            <Fade left cascade>
              <li>
                <img src={ic3} alt="" />
                <p>Высокие потолки - 4 метра</p>
              </li>
            </Fade>
            <Fade right cascade>
              <li>
                <img src={ic4} alt="" />
                <p>Открытая кухня с премиальными решениями</p>
              </li>
            </Fade>
          </ul>
        </div>
        <div className="fourthRight">
          <div className="friends">
            <div>
              <img src={icon1} alt="" />
              <p>14</p>
              <span>
                количество <br />
                юнитов в комплексе
              </span>
            </div>
            <div>
              <img src={icon2} alt="" />
              <p>3</p>
              <span>
                виллы <br />
                210 м2
              </span>
            </div>
          </div>
          <div className="friends">
            <div>
              <img src={icon3} alt="" />
              <p>10</p>
              <span>
                виллы <br />
                по 168 м2
              </span>
            </div>
            <div>
              <img src={icon4} alt="" />
              <p>1</p>
              <span>
                вилла <br />
                230 м2
              </span>
            </div>
          </div>
          <Zoom>
            {" "}
            <div className="yer">
              <b>ВНЖ</b> для инвесторов на 2 года
            </div>
          </Zoom>
        </div>
      </div>
      <div
        className="photki"
        style={
          slider == 1
            ? { transform: "translateX(0)" }
            : slider == 2
            ? { transform: "translateX(-100vw)" }
            : slider == 3
            ? { transform: "translateX(-200vw)" }
            : slider == 4
            ? { transform: "translateX(-300vw)" }
            : {}
        }
      >
        <Fade top cascade>
          <img src={document.body.clientWidth > 640 ? i1 : m1} alt="" />
          <img src={document.body.clientWidth > 640 ? i2 : m2} alt="" />
          <img src={document.body.clientWidth > 640 ? i3 : m3} alt="" />
          <img src={document.body.clientWidth > 640 ? i4 : m4} alt="" />
        </Fade>
      </div>{" "}
      {document.body.clientWidth < 640 && (
        <div className="sliderBut">
          <div
            style={
              slider == 1
                ? {
                    width: "14vw",
                    background: "rgba(72, 130, 85, 1)",
                    borderRadius: "12vw",
                  }
                : {}
            }
            onClick={() => setSlider(1)}
          ></div>
          <div
            onClick={() => setSlider(2)}
            style={
              slider == 2
                ? {
                    width: "14vw",
                    background: "rgba(72, 130, 85, 1)",
                    borderRadius: "12vw",
                  }
                : {}
            }
          ></div>
          <div
            onClick={() => setSlider(3)}
            style={
              slider == 3
                ? {
                    width: "14vw",
                    background: "rgba(72, 130, 85, 1)",
                    borderRadius: "12vw",
                  }
                : {}
            }
          ></div>
          <div
            onClick={() => setSlider(4)}
            style={
              slider == 4
                ? {
                    width: "14vw",
                    background: "rgba(72, 130, 85, 1)",
                    borderRadius: "12vw",
                  }
                : {}
            }
          ></div>
        </div>
      )}
      <div className="tipes">
        {document.body.clientWidth > 640 ? (
          <Flip left cascade>
            <img src={type1} alt="" />
            <img src={type2} alt="" />
            <img src={type3} alt="" />
          </Flip>
        ) : (
          <img src={mobCen} className="mobCen" />
        )}
      </div>
      <div className="noutEm">
        {" "}
        <Bounce right>
          <img
            className="noutImg"
            src={document.body.clientWidth > 640 ? nout : notic}
            alt=""
          />
        </Bounce>
        <div className="noutCont">
          <h3>ON-line</h3>
          <h2>показ</h2>
          <p>
            Закрытые предпродажи <br />
            начались
          </p>
          <div>
            <img src={ii} alt="" />
            <p>
              <b> Запишитесь на онлайн показ,</b>
              <br /> чтобы увидеть все первыми
            </p>
          </div>

          <div
            className="button"
            onClick={() => {
              openForm(true);
            }}
          >
            Записаться<div className="flare"></div>
          </div>
        </div>
      </div>
      <div className="realInv">
        <img src={palm} className="fonicPalm" alt="" />
        <h3>
          Real <br /> Dream Investment
        </h3>
        <h4>
          МЫ СОЗДАЕМ ДЛЯ ВАС <b>НАДЕЖНЫЕ РЕШЕНИЯ</b> В СФЕРЕ НЕДВИЖИМОСТИ
        </h4>
        <div className="realCont">
          <Fade left>
            {" "}
            <div className="realContLeft">
              Команда Real Dream <br />
              Investment профессионалы <br /> в области недвижимости
            </div>
          </Fade>{" "}
          <Fade right>
            <div className="realContRight">
              <img src={crug} alt="" />
              <p>
                Нам принадлежат лучшие участки района Чемаги <br /> с богатой
                историей и культурным наследием
              </p>
            </div>
          </Fade>
        </div>
        <div className="plans">
          <p>В ПЛАНАХ НА ПОСТРОЙКУ</p>
          <div className="greenL"></div>
          <div className="kukla">
            <b>5 эко-комплексов</b> {document.body.clientWidth > 640 && "-"} 5
            драгоценных камней
          </div>
        </div>
        <div className="almaz">
          <img src={alm1} alt="" />
          <p>
            Каждый комплекс имеет{" "}
            <span>
              свою особенность и дизайн <br /> интерьера,
            </span>
            связанный с именем камня.
          </p>
          <img src={alm2} alt="" />
        </div>
      </div>
    </div>
  );
}
