import "./style.css";
import fog from "./img/sss.png";
import logo from "./img/logo.png";
import tg from "./img/tg.png";
import wt from "./img/wt.png";
import ic1 from "./img/ic1.svg";
import ic2 from "./img/ic2.svg";
import palm from "./img/palm.svg";
import { useEffect, useRef, useState } from "react";
import rt from "./img/rt.png";
import footic from "./img/footic.png";
import palm1 from "./img/palm1.png";
import palm2 from "./img/palm2.png";
import fr from "./img/fr.png";
import sr from "./img/sr.png";
import palm3 from "./img/palm3.png";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";
import fb from "./img/fb.svg";
import inst from "./img/inst.svg";
import phone from "./img/phone.svg";

import mapImg from "./img/map.png";
import mobTab from "./img/mobTab.png";
import FormL from "../../Components/Form";
import sdacha from "./img/sdacha.svg";
import proc from "./img/proc.svg";
import Bot from "../../Components/bot";
import mob1 from "./img/mob1.png";
import mob2 from "./img/mob2.png";
import mob3 from "./img/mob3.png";
import ban from "./img/ban.svg";
import popuk from "./img/popuk.png";
export default function Posad() {
  // The state for our timer
  const [form, isForm] = useState(false);
  return (
    <div>
      <div className="headTT">
        {form && <FormL closePop={isForm}></FormL>}
        <img src={palm1} className="palm1" alt="" />
        <img src={palm2} alt="" className="palm2" />
        <img src={palm3} alt="" className="palm3" />
        <img src={sdacha} alt="" className="sdacha" />
        <div className="headerTop">
          <img src={logo} alt="" className="logo" />
          <p>Задайте ваш вопрос</p>
          <a
            href="https://t.me/oporadigital_bot"
            target="_blank"
            className="mess"
          >
            <img src={tg} alt="" />
          </a>
          <a
            href="https://t.me/oporadigital_bot"
            target="_blank"
            className="mess"
          >
            <img src={wt} alt="" />{" "}
          </a>
          <a className="nums" href="tel:+84 38 242 5892">
            +84 38 242 5892
          </a>
        </div>
        <div className="posadCont">
          <Zoom left duration={600}>
            <div className="leftPosad">
              <h1>
                Новый
                <span> PREMIUM</span>
                <br />
                комплекс на бали <img src={palm} alt="" />
              </h1>
              <div className="aqua">AQUAMARINE</div>
              {document.body.clientWidth > 640 && (
                <div className="preim">
                  <div className="preimEm">
                    <img src={fr} alt="" />
                    <div>
                      <p>
                        70
                        <br />
                        <b>метров</b>
                      </p>
                      <span>до океана</span>
                    </div>
                  </div>
                  <div className="preimEm">
                    <img src={sr} alt="" />
                    <div>
                      <p>
                        в 20
                        <br />
                        <b>минутах</b>
                      </p>
                      <span>Диснейленд</span>
                    </div>
                  </div>
                  <img src={proc} alt="" />
                </div>
              )}
              {document.body.clientWidth < 640 && (
                <div>
                  <img className="mobs" src={mob1} alt="" />
                  <img className="mobs" src={mob2} alt="" />
                  <img className="mobs2" src={mob3} alt="" />
                  <img src={popuk} className="popuk" alt="" />
                </div>
              )}
              <div className="loska">
                <div className="linePosad"></div>
                {document.body.clientWidth > 640 && (
                  <div className="loskaCont">
                    <b>
                      Оставьте заявку:{" "}
                      {document.body.clientWidth < 640 && <br></br>}{" "}
                      <span>получите видео</span>
                    </b>
                    <p>
                      <span>и расчёт доходности</span> прямо сейчас!
                    </p>
                  </div>
                )}
                {document.body.clientWidth < 640 && <img src={ban}></img>}
              </div>
              {document.body.clientWidth > 640 && (
                <div className="buttonTime">
                  <div
                    className="button"
                    id="btnOpenForm"
                    onClick={() => isForm(true)}
                  >
                    ОСТАВИТЬ ЗАЯВКУ
                    <span class="flare"></span>
                  </div>
                  <div className="povish">
                    повышение
                    {document.body.clientWidth > 640 && <br />}
                    цены через
                  </div>
                  <CountDown hours={18} minutes={46}></CountDown>
                </div>
              )}
            </div>
          </Zoom>
          <Zoom top>
            <div className="rightPosad">
              {document.body.clientWidth > 640 && (
                <div>
                  <img src={rt} className="tab" alt="" />
                </div>
              )}
              {document.body.clientWidth < 640 && (
                <img className="mobTab" src={mobTab}></img>
              )}
              {document.body.clientWidth < 640 && (
                <div className="buttonTime">
                  <div className="button" onClick={() => isForm(true)}>
                    ОСТАВИТЬ ЗАЯВКУ
                    <span class="flare"></span>
                  </div>
                  <div className="flexic">
                    <div className="povish">повышение цены через</div>
                    <CountDown days={5} hours={18} minutes={46}></CountDown>
                  </div>
                </div>
              )}
            </div>
          </Zoom>
        </div>
      </div>
      <Bot></Bot>
      <footer>
        <div className="footerContent">
          {/* <div className="leftFooter">
            <YMaps>
              <div className="cart">
                <Map
                  width={document.body.clientWidth > 640 ? "50vw" : "100%"}
                  height={document.body.clientWidth > 640 ? "25vw" : "50vw"}
                  defaultState={{
                    center: [-8.641820059438109, 115.10631668650474],
                    zoom: 10,
                  }}
                >
                  <Placemark
                    geometry={[-8.641820059438109, 115.10631668650474]}
                  />
                </Map>
              </div>
            </YMaps>
          </div> */}
          <div className="rightFooter">
            <img src={footic} alt="" className="footerLogo" />
            <div className="phoneI">
              <img src={phone} alt="" />
              <a href="tel:+84 38 242 5892">+84 38 242 5892</a>
            </div>
            <p>9:00 - 18:00</p>
            <h4>социальные сети</h4>
            <div className="messFooter">
              <a
                href="https://www.facebook.com/realdream.invest.aquamarine"
                target="_blank"
              >
                <img src={fb} alt="" />
              </a>
              <a href="https://instagram.com/realdream.invest" target="_blank">
                <img src={inst} alt="" />
              </a>
              <a href="https://t.me/oporadigital_bot" target="_blank">
                <img src={tg} alt="" />
              </a>
            </div>
            <a href="./politics.html" target="_blank" className="politic">
              Политика конфиденциальности
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export const CountDown = ({ hours = 0, minutes = 0, seconds = 0 }) => {
  const [paused, setPaused] = useState(false);
  const [over, setOver] = useState(false);
  const [[h, m, s], setTime] = useState([hours, minutes, seconds]);

  const tick = () => {
    if (paused || over) return;

    if (h === 0 && m === 0 && s === 0) {
      setOver(true);
    } else if (m === 0 && s === 0) {
      setTime([h - 1, 59, 59]);
    } else if (s == 0) {
      setTime([h, m - 1, 59]);
    } else {
      setTime([h, m, s - 1]);
    }
  };

  const reset = () => {
    setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);
    setPaused(false);
    setOver(false);
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  return (
    <div>
      <p className="tims">
        <span>05</span>
        <span>{`${h.toString().padStart(2, "0")}`}</span>
        <span>{`${m.toString().padStart(2, "0")}`}</span>
        <span>{`${s.toString().padStart(2, "0")}`}</span>
      </p>
      <div className="hor">
        <span className="day">ДНЕЙ</span>
        <span>ЧАСОВ</span>
        <span>МИНУТ</span>
        <span>СЕКУНД</span>
      </div>
      <div>{over ? "Time's up!" : ""}</div>
    </div>
  );
};
