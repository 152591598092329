import "./style.css";
import tabl from "./img/tabl.png";
import secTabl from "./img/secondtb.png";
import cart from "./img/cart.svg";
import stars from "./img/starts.svg";
import car from "./img/car.svg";
import mess from "./img/mess.png";
import island from "./img/island.png";
import obl from "./img/obl.png";
import Marquee from "react-fast-marquee";
import sevem from "./img/seven.png";
import metks from "./img/metks.svg";
import palm from "./img/palm.png";
import Zoom from "react-reveal/Zoom";
import rz from "./img/rz.svg";
import Rotate from "react-reveal/Rotate";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";
import mobMess from "./img/mobMess.png";
import huba from "./img/huba.svg";
import mobSeven from "./img/mobSeven.png";
import heh from "./img/heh.svg";
import FormL from "../../Form";

export default function SecondBl({ openForm }) {
  return (
    <div className="secondBl">
      <div className="cart">
        <div className="cartLeft">
          <h2>Район</h2>
          <h3>
            <img src={tabl} alt="" /> Чемаги
          </h3>{" "}
          <p className="sea">
            Он расположен на берегу океана,{" "}
            <span>
              где строительство на {document.body.clientWidth > 640 && <br />}{" "}
              рисовых полях запрещено!{" "}
            </span>
            <br /> Зеленая атмосфера Бали останется здесь навсегда
          </p>
        </div>
        <Zoom right duration={1300}>
          <div className="cartRight">
            <img src={cart} alt="" />
          </div>
        </Zoom>
      </div>
      <div className="thras">
        <div className="thrasCont">
          <h4>чангу</h4>
          <h4>
            <img src={secTabl} alt="" /> рядом
          </h4>
          <p className="dinamic">
            Это{" "}
            <span>
              динамичное <br /> сердце{" "}
            </span>
            острова Бали
          </p>
          <div className="grayLine"></div>
          <div className="sochen">
            которое сочетает в себе культурное наследие и современную жизнь.{" "}
            <span>Это туристический, деловой и административный центр,</span>{" "}
            где сконцентрированы основные достопримечательности, развлечения,
            офисы компаний и госучреждения.
          </div>
          <Flip left duration={1300}>
            <img className="car" src={car} alt="" />
          </Flip>
        </div>
        <div className="stars">
          <p>Более</p>
          <Zoom>
            <span>80%</span>
          </Zoom>

          <div>
            Инвесторов рассматривают <br /> этот район
          </div>
          <img src={stars} alt="" />
        </div>{" "}
        <Rotate bottom right>
          <img
            src={document.body.clientWidth > 640 ? mess : mobMess}
            className="scop"
            alt=""
          />
        </Rotate>
      </div>
      <div className="anm">
        <div className="obls">
          <Marquee speed={10} gradien={true}>
            <img src={obl} className="obl" alt="" />
            <img src={obl} className="obl" alt="" />
            <img src={obl} className="obl" alt="" />
            <img src={obl} className="obl" alt="" />
          </Marquee>
        </div>

        <img src={island} className="island" alt="" />
        <Zoom>
          <img
            src={document.body.clientWidth > 640 ? sevem : mobSeven}
            className="sevem"
            alt=""
          />
        </Zoom>
        <Fade top>
          <img
            src={document.body.clientWidth > 640 ? metks : huba}
            className="metks"
            alt=""
          />
        </Fade>
        <img src={palm} className="palmg" alt="" />
      </div>
      <p className="ins">Развитая инфраструктура</p>
      <div className="infpl">
        <img src={document.body.clientWidth > 640 ? rz : heh} alt="" />
      </div>
      <div
        className="button"
        onClick={() => {
          openForm(true);
        }}
      >
        Узнать подробнее<div className="flare"></div>
      </div>
    </div>
  );
}
