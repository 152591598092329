import "./style.css";
import leftImg from "./img/left.png";
import palm from "./img/palm.png";
import crug from "./img/crug.png";
import ic1 from "./img/ic1.svg";
import ic2 from "./img/ic2.svg";
import ic3 from "./img/ic3.svg";
import ic4 from "./img/ic4.svg";
import krugs from "./img/krugs.png";
import prib from "./img/prib.svg";
import popka from "./img/popka.png";
import halt from "./img/haslt.svg";
import Fade from "react-reveal/Fade";
import Rotate from "react-reveal/Rotate";
import Flip from "react-reveal/Flip";
import Zoom from "react-reveal/Zoom";
import cifer from "./img/cifer.svg";
import Roll from "react-reveal/Roll";
import cif from "./img/cif.svg";
export default function FirstBl() {
  return (
    <div className="firstBl">
      <div className="firstBlContent">
        <Fade left>
          <div className="leftFirstBl">
            <img src={leftImg} alt="" />
          </div>
        </Fade>
        <div className="rightFirstBl">
          <h3>Бали</h3>
          <div className="firstBlInvest">
            <div className="greenLine"></div>
            <p>
              Ваши инвестиции в свой <br /> кусочек рая на земле
            </p>
            <img src={palm} alt="" />
          </div>
          <Fade top>
            <div className="plyases">
              <div className="firstPlyas">
                <p>топ-4</p>
                <span>
                  в рейтинге Forbes по инвестиционной привлекательности в мире
                </span>
              </div>
              <div className="secondpl">
                <div className="arc">
                  <img src={ic1} alt="" />
                  <p>8-10%</p>
                  <div className="grLine"></div>
                  <span>
                    Рост экономики <br /> Бали в год
                  </span>
                </div>
                <div className="arc">
                  <img src={ic2} alt="" />
                  <p>80%</p>
                  <div className="grLine"></div>
                  <span>
                    Средняя <br /> загрузка отелей
                  </span>
                </div>
              </div>
            </div>
          </Fade>
          <div className="krugs">
            <img src={crug} alt="" />
            <p>
              Бали - это тропический остров в Индонезии, называемый{" "}
              <span>одним из самых прекрасных мест на Земле</span>
            </p>
          </div>
        </div>
      </div>
      <div className="nextFbl">
        <Zoom>
          <img src={cif} className="cif" alt="" />{" "}
        </Zoom>
        <h2>Манящие</h2>
        <h3>
          <div className="greenL"></div>пляжи
        </h3>
        <p>
          <Zoom>
            Белоснежный песок, сумасшедшие закаты, изумрудные рисовые террасы,
            роскошная растительность и удивительная культура ежегодно
            притягивают сюда миллионы туристов со всего мира
          </Zoom>
        </p>
        <div className="ceto">
          <div>
            <img className="krugles" src={krugs} alt="" />
            <div className="stats">
              <div className="statsem">
                <img src={ic3} alt="" />
                <div>
                  <p>6.000.000 </p>
                  <span>
                    Туристов в год <br /> посещают Бали
                  </span>
                </div>
              </div>
              <div className="statsem">
                <img src={ic4} alt="" />
                <div>
                  <p>10%</p>
                  <span>
                    Средний {document.body.clientWidth < 640 && <br />}
                    ежегодный <br /> рост туризма
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="pop">
            <Fade top>
              <img className="popka" src={popka} alt="" />
            </Fade>{" "}
            <Roll right>
              <img className="txter" src={halt} alt="" />
            </Roll>
          </div>
        </div>
        <Fade right>
          <img
            className="prib"
            src={document.body.clientWidth > 640 ? prib : cifer}
            alt=""
          />
        </Fade>
      </div>
    </div>
  );
}
