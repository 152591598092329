import "./style.css";
import rt from "./img/rig.png";
import lf from "./img/left.png";
import like from "./img/like.svg";
import r1 from "./img/r1.png";
import r2 from "./img/r2.png";
import r3 from "./img/r3.png";
import own from "./img/down.png";
import palm from "./img/plam.png";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import im1 from "./img/1.png";
import im2 from "./img/2.png";
import im3 from "./img/3.png";
import im4 from "./img/4.png";
import im5 from "./img/5.png";
import im6 from "./img/6.png";
import im7 from "./img/7.png";
import im8 from "./img/8.png";
import { useState } from "react";
export default function ThirdBl({ openForm }) {
  const [slider, setSlider] = useState(1);
  return (
    <div className="thirdBl">
      <img src={palm} className="thirPalm" alt="" />
      <div className="thContent">
        <div className="thLeftContent">
          <Fade left>
            <h3>AQUAMARINE</h3>
          </Fade>
          <h2>эко-элит комплекс</h2>
          <div className="grLine"></div>
          <p className="qka">
            <b>Аквамарин -</b> идеальное воплощение концепции
            <span>"жизнь у океана"</span>. Все виллы построены так, что вы
            ежедневно будете видеть закат над окенаном из вашего окна
          </p>
        </div>
        <div className="thRightCont">
          <Fade right>
            <img className="rts" src={rt} alt="" />
          </Fade>
          <Fade delay={600}>
            <img src={like} className="lik" alt="" />
          </Fade>
        </div>
      </div>
      <div className="scthContent">
        <Fade left>
          <img src={lf} alt="" />
        </Fade>
        <div className="bork">
          <Flip left>
            <div>
              <img src={r1} alt="" />
              <p>
                Частная закрытая br <b>дорожка к пляжу</b>
                <br />
                <span>*70 метров от вашей двери</span>
              </p>
            </div>
          </Flip>
          <Flip right>
            <div>
              <img src={r2} alt="" />
              <p>
                <b>Управляющая компания,</b> которая управляет более чем 200
                объектами и обеспечивает обслуживание на уровне 5* отеля.
              </p>
            </div>
          </Flip>
          <Flip left>
            <div>
              <img src={r3} alt="" />
              <p>
                Территория комплекса окружена рисовыми полями, принадлежащими
                Real Dream Investment, которые
                <b>НЕ ПОДЛЕЖАТ дальнейшей застройке</b>
              </p>
            </div>
          </Flip>
        </div>
      </div>
      {document.body.clientWidth > 640 ? (
        <img src={own} className="ownik" alt="" />
      ) : (
        <div
          className="looker"
          style={
            slider == 1
              ? { transform: "translateX(0)" }
              : slider == 2
              ? { transform: "translateX(-100vw)" }
              : slider == 3
              ? { transform: "translateX(-200vw)" }
              : slider == 4
              ? { transform: "translateX(-300vw)" }
              : slider == 5
              ? { transform: "translateX(-400vw)" }
              : slider == 6
              ? { transform: "translateX(-500vw)" }
              : slider == 7
              ? { transform: "translateX(-600vw)" }
              : slider == 8
              ? { transform: "translateX(-700vw)" }
              : {}
          }
        >
          <img src={im1} alt="" />
          <img src={im2} alt="" />
          <img src={im3} alt="" />
          <img src={im4} alt="" />
          <img src={im5} alt="" />
          <img src={im6} alt="" />
          <img src={im7} alt="" />
          <img src={im8} alt="" />
        </div>
      )}
      {document.body.clientWidth < 640 && (
        <div className="sliderBut">
          <div
            style={
              slider == 1
                ? {
                    width: "14vw",
                    background: "rgba(72, 130, 85, 1)",
                    borderRadius: "12vw",
                  }
                : {}
            }
            onClick={() => setSlider(1)}
          ></div>
          <div
            onClick={() => setSlider(2)}
            style={
              slider == 2
                ? {
                    width: "14vw",
                    background: "rgba(72, 130, 85, 1)",
                    borderRadius: "12vw",
                  }
                : {}
            }
          ></div>
          <div
            onClick={() => setSlider(3)}
            style={
              slider == 3
                ? {
                    width: "14vw",
                    background: "rgba(72, 130, 85, 1)",
                    borderRadius: "12vw",
                  }
                : {}
            }
          ></div>
          <div
            onClick={() => setSlider(4)}
            style={
              slider == 4
                ? {
                    width: "14vw",
                    background: "rgba(72, 130, 85, 1)",
                    borderRadius: "12vw",
                  }
                : {}
            }
          ></div>
          <div
            onClick={() => setSlider(5)}
            style={
              slider == 5
                ? {
                    width: "14vw",
                    background: "rgba(72, 130, 85, 1)",
                    borderRadius: "12vw",
                  }
                : {}
            }
          ></div>
          <div
            onClick={() => setSlider(6)}
            style={
              slider == 6
                ? {
                    width: "14vw",
                    background: "rgba(72, 130, 85, 1)",
                    borderRadius: "12vw",
                  }
                : {}
            }
          ></div>
          <div
            onClick={() => setSlider(7)}
            style={
              slider == 7
                ? {
                    width: "14vw",
                    background: "rgba(72, 130, 85, 1)",
                    borderRadius: "12vw",
                  }
                : {}
            }
          ></div>
          <div
            onClick={() => setSlider(8)}
            style={
              slider == 8
                ? {
                    width: "14vw",
                    background: "rgba(72, 130, 85, 1)",
                    borderRadius: "12vw",
                  }
                : {}
            }
          ></div>
        </div>
      )}
      <div className="getVideo">
        <p>
          Получить <b>уникальное видео</b> комплекса
        </p>
        <div
          className="button"
          onClick={() => {
            openForm(true);
          }}
        >
          Получить видео<div className="flare"></div>
        </div>
      </div>
    </div>
  );
}
