import FirstBl from "../../Components/LandComp/FirstBl";
import FourthBl from "../../Components/LandComp/FourthBl";
import HeaderBl from "../../Components/LandComp/Header";
import SecondBl from "../../Components/LandComp/SecondBl";
import ThirdBl from "../../Components/LandComp/ThirdBl";
import Bot from "../../Components/bot";
import footic from "../Posad/img/footic.png";
import phone from "../Posad/img/phone.svg";
import fb from "../Posad/img/fb.svg";
import inst from "../Posad/img/inst.svg";
import tg from "../Posad/img/tg.png";
import "../../nextart/stylesheet.css";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import "./style.css";
import FormL from "../../Components/Form";
import { useState } from "react";
export default function Landing() {
  const [form, openForm] = useState(false);
  return (
    <div className="Landing">
      {/*   {form && <FormL closePop={openForm}></FormL>} */}
      <HeaderBl openForm={openForm}></HeaderBl>
      <FirstBl></FirstBl>
      <SecondBl openForm={openForm}></SecondBl>
      <ThirdBl openForm={openForm}></ThirdBl>
      <FourthBl openForm={openForm}></FourthBl>
      <Bot></Bot>
      <footer>
        <div className="footerContent">
          <div className="leftFooter">
            <YMaps>
              <div className="cartd">
                <Map
                  width={document.body.clientWidth > 640 ? "50vw" : "100%"}
                  height={document.body.clientWidth > 640 ? "25vw" : "50vw"}
                  defaultState={{
                    center: [-8.641820059438109, 115.10631668650474],
                    zoom: 10,
                  }}
                >
                  <Placemark
                    geometry={[-8.641820059438109, 115.10631668650474]}
                  />
                </Map>
              </div>
            </YMaps>
          </div>
          <div className="rightFooter">
            <img src={footic} alt="" className="footerLogo" />
            <div className="phoneI">
              <img src={phone} alt="" />
              <a href="tel:+84 38 242 5892">+84 38 242 5892</a>
            </div>
            <p>9:00 - 18:00</p>
            <h4>социальные сети</h4>
            <div className="messFooter">
              <a
                href="https://www.facebook.com/marvelestatebali"
                target="_blank"
              >
                <img src={fb} alt="" />
              </a>
              <a href="https://www.instagram.com/marvel_estate" target="_blank">
                <img src={inst} alt="" />
              </a>
              <a href="https://t.me/alex_bydevelop" target="_blank">
                <img src={tg} alt="" />
              </a>
            </div>
            <a href="./politics.html" target="_blank" className="politic">
              Политика конфиденциальности
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
